<template>
	<div class="wrap__content">
		<div class="container news__container">
			<div class="news__all__list">
				<p class="news__top__link mr-4 pointer" @click="$router.push('/' + $i18n.locale)">
					{{ $t('site.home_page') }}
				</p>
				<p class="news__top__link news__link__active pointer">{{ $t('site.menu.sitemap') }}</p>
			</div>

			<div class="contact-page__content">
				<br />
				<div class="section__title">
					{{ $t('site.menu.sitemap') }}
				</div>

				<div class="contact-page__block">
					<div class="contact__block--info">
						<div class="sitemap__list--block">
							<div class="main__sidebar" v-bind:class="{ 'main__sidebar--active': sidebarClick }">
								<div class="main__sidebar--burger">
									<a class="sidebar__burger" @click="sidebarClick = !sidebarClick">
										<svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M3 9h12M3 4.5h12M3 13.5h12" stroke="#52A5FC" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg>
									</a>
								</div>

								<nav class="main__sidebar--nav sitemap__list">
									<ul class="menu sidebar__nav">
										<li class="sidebar-item">
											<a class="sidebar-link">
												<i class="sidebar-icon">
													<svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M3.19 9.237l9-7 9 7v11a2 2 0 01-2 2h-14a2 2 0 01-2-2v-11z" stroke="#bcbcbc" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/><path d="M9.19 22.237v-10h6v10" stroke="#bcbcbc" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg>
												</i>
												{{ $t('site.home_page') }}
											</a>
											<ul class="menu sidebar__nav">
												<li class="sidebar-item">
													<router-link :to="'/' + $i18n.locale + '/news'" class="sidebar-link">
														<i class="sidebar-icon">
															<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M19.82 2H4.18A2.18 2.18 0 002 4.18v15.64A2.18 2.18 0 004.18 22h15.64A2.18 2.18 0 0022 19.82V4.18A2.18 2.18 0 0019.82 2zM7 2v20M17 2v20M2 12h20M2 7h5M2 17h5M17 17h5M17 7h5" stroke="#bcbcbc" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg>
														</i>
														{{ $t('site.home.news.title') }}
													</router-link>
												</li>
												<li class="sidebar-item">
													<router-link :to="'/' + $i18n.locale + '/contact'" class="sidebar-link">
														<i class="sidebar-icon">
															<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M22 16.92v3a2 2 0 01-2.18 2 19.79 19.79 0 01-8.63-3.07 19.501 19.501 0 01-6-6 19.79 19.79 0 01-3.07-8.67A2 2 0 014.11 2h3a2 2 0 012 1.72c.127.96.361 1.903.7 2.81a2 2 0 01-.45 2.11L8.09 9.91a16 16 0 006 6l1.27-1.27a2 2 0 012.11-.45c.907.338 1.85.573 2.81.7A2 2 0 0122 16.92z" stroke="#bcbcbc" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg>
														</i>
														{{ $t('site.menu.contact') }}
													</router-link>
												</li>
												<li class="sidebar-item">
													<router-link :to="'/' + $i18n.locale + '/sitemap'" class="sidebar-link">
														<i class="sidebar-icon">
															<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M8 6h13M8 12h13M8 18h13M3 6h.01M3 12h.01M3 18h.01" stroke="#bcbcbc" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg>
														</i>
														{{ $t('site.menu.sitemap') }}
													</router-link>
												</li>

												<li class="sidebar-item">
													<router-link :to="'/' + $i18n.locale + '/login'" class="sidebar-link">
														<i class="sidebar-icon">
															<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M20 21v-2a4 4 0 00-4-4H8a4 4 0 00-4 4v2M12 11a4 4 0 100-8 4 4 0 000 8z" stroke="#000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg>
														</i>
														{{ $t('site.authorization.title') }}
													</router-link>
												</li>
												<li class="sidebar-item">
													<router-link
														:to="'/' + $i18n.locale + '/registration'"
														class="sidebar-link"
													>
														<i class="sidebar-icon">
															<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M17 21v-2a4 4 0 00-4-4H5a4 4 0 00-4 4v2M9 11a4 4 0 100-8 4 4 0 000 8zM23 21v-2a4 4 0 00-3-3.87M16 3.13a4 4 0 010 7.75" stroke="#000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg>
														</i>
														{{ $t('site.authorization.registration') }}
													</router-link>
												</li>
											</ul>
										</li>
									</ul>
								</nav>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'index',
}
</script>

<style scoped></style>
